import React from 'react';

const Template = (props) => {
	return (
		<div className="main-template">

		</div>
	);
}
export default Template;
